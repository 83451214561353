import React from 'react'

export default function Alert(props) {
  const capitalized=(word)=>{
    const lower=word.toLowerCase()
    return lower.charAt(0).toUpperCase() + lower.slice(1)
  }
  const alertWarn={
    height:'80px',
  }
  return (
    <div style={alertWarn}>
       {
        props.alertStatus &&  <div>
        <div className={`alert alert-${props.alertStatus.type} alert-dismissible fade show`} role="alert">
            <strong>{capitalized(props.alertStatus.type)}: {props.alertStatus.message}</strong> 
        </div>
    </div>
       }
       
    </div>
   
  )
}
