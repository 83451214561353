// import logo from './logo.svg';
import { useState } from 'react';
import './Appa.css';
import About from './components/About';
import Navbar from './components/Navbar';
import TextUtils from './components/TextUtils';
import Alert from './components/Alert';
import { BrowserRouter, Routes,Route } from "react-router-dom";


function App() {
  const [mode,setMode]=useState('light')
  const [alertText,setAlertText]=useState(null);

  const showingAlert=(message,type)=>{
    setAlertText({
      message:message,
      type:type,
    });
    setTimeout(()=>{
      setAlertText(null)
    },4000)
  }
  const toggleMode=()=>{
    if (mode === 'light'){
       
      document.body.style.backgroundColor='#010825'
      setMode('dark')
      showingAlert('Dark Mode Called','success')
      document.title='Sameer - Dark Mode '
      // setInterval(()=>{
      //   document.title='Installing......'
      // },1000)
      // setInterval(()=>{
      //   document.title='Done'
      // },4000)
    }
    else{
      
      document.body.style.backgroundColor='white'
      setMode('light')
      showingAlert('Light Mode Called','success')
      document.title='Sameer - light Mode '
    }
  }
  

  return (
    <>  
      {/* <Navbar title="SHAIK ABDUL SAMEER" aboutText="About Us"/> */}
      {/* <Navbar /> */}
      
      <BrowserRouter>
      <Navbar  aboutText="About Us" mode={mode} toggleMode={toggleMode}/>
      <Alert alertStatus={alertText}/>
        <Routes>
          <Route exact path='/' element={<TextUtils showingAlert ={showingAlert} mode={mode}/>}/>
        </Routes>
        <Routes>
          <Route exact path='/about' element={ <About  mode={mode}/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
