import React, { useState } from 'react'

export default function TextUtils(props) {
  const convertToUpper=()=>{
   const upperChar=text.toUpperCase()
   setText(upperChar)
   props.showingAlert('UpperCase called','success')
  }
  const convertToLower=()=>{
   const lowerChar=text.toLowerCase()
   setText(lowerChar)
   props.showingAlert('LowerCase called','success')
  }
  const convertToClear=()=>{
    setText('')
    props.showingAlert('Text Removed called','success')
  }
  const convertToRemoveSpace=()=>{
    const spaceText=text.replace(/\s+/g,' ');
    setText(spaceText)
    props.showingAlert('Remove space called','success')
  }
  const convertToCopy=()=>{
    let text=document.getElementById('textbox')
    navigator.clipboard.writeText(text.value);
    props.showingAlert('Copyied called','success')
  }
  const onChangeEvent=(event)=>{
    // console.log('onchange Called')
    const newText=event.target.value
    setText(newText)
  }

  
  
  const [text,setText]=useState('')
  return (
    <>
    <div className="container my-4">
      <h2 style={{color:props.mode === 'dark' ? 'white': 'black'}}>Try Text Utils : Word Counter,Character Counter,Remove Spaces</h2>
            <div className="form-group">
                <textarea className="form-control" value={text} onChange={onChangeEvent} id="textbox" rows="8" style={{backgroundColor:props.mode === 'dark' ? '#13466e': 'white', color:props.mode === 'dark' ? 'white': 'black'}}></textarea>
            </div>
        <div className='my-4'>
          <button disabled={text.length === 0} className="btn btn-primary m-1" onClick={convertToUpper}>Convert to Uppercase</button>
          <button disabled={text.length === 0} className="btn btn-primary m-1" onClick={convertToLower}>Convert to Lowercase</button>
          <button disabled={text.length === 0} className="btn btn-primary m-1" onClick={convertToClear}>CLEAR</button>
          <button disabled={text.length === 0} className="btn btn-primary m-1" onClick={convertToCopy}>Copy</button>
          <button disabled={text.length === 0} className="btn btn-primary m-1" onClick={convertToRemoveSpace}>Remove Space</button>

        </div>
    </div>
    <div className="container" style={{color:props.mode === 'dark' ? 'white': 'black'}}>
      <h2>Your text summary : </h2>
      <p>{text.split(/\s+/).filter(element=>element.length !== 0).length} Words and {text.length} Characters</p>
      <h3>Preview : </h3>
      <p>{`${text.length>0 ? text:'Nothing to preview'}`}</p>   
    </div>
    </>
  )
}
